.role_table{
    width: 55px;
    
}


.text_table {
    font-size: 15px;
}

.role_label{
    position: static;
}
    

.fixed-header {
    position: sticky;
    top: 0;
    /* background-color: transparent;  */
    z-index: 1; 
  }
  
  .scrollable-body {
    max-height: 900px;
    overflow-y: auto;
  }

  @media screen and (max-width: 767.98px) {
    .icon{
      height:15px;
      width:15px;
    }
    .font{
        font-size:12px;
    }
  }