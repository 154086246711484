//
// Custom
//

@use "sass:map";

.scroll-margin {
    scroll-margin-top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.sticky-top-size {
    top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
    --#{$prefix}border-opacity: 0;
}
.mw-50 {
    max-width: 300px;
}
.mh-90 {
    min-height: 90px !important;
}
.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 10px 5px;
    border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}
.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}
.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}

.multi-select .searchWrapper {
    border: none;
}

.custom-width .multiSelectContainer input {
    width: 100% !important;
}

.edit-icon {
    color: green;
}

.text-align {
    text-align: justify;
}

.equalizer-icon {
    color: gray;
}

.eye-icon {
    color: #6880fb;
}

.eye-icon:hover {
    color: rgb(26, 26, 26);
}

.question-icon {
    fill: gray !important;
}

.question-icon:hover {
    color: rgb(26, 26, 26) !important;
}

.device-icon {
    color: gray;
}

.svg-icon--material {
    width: 20px;
    height: 20px;
}
.delete-icon {
    color: red;
}
.flip-icon {
    transform: scaleY(-1);
}

.table-img {
    width: 100px;
    height: auto;
}

.sku-img {
    width: 100px;
    height: 100px;
}

.sku-icon {
    width: 90px;
    height: 30px;
}

.section-icon {
    height :30px;
}

.form-labels {
    font-size: 15px;
    color: #333;
    margin-bottom: 5px;
}
.user-input {
    background-color: #9e9e9e;
    color: #9e9e9e;
}

.loader-div {
    position: absolute;
    left: 50%;
    top: 50%;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.skeleton {
    height: 500px;
    width: 1200px;
}

.disabled-button {
    background-color: #bdbdbd !important;
    color: white !important;
}

.input-form {
    height: 47px;
}

.date-picker {
    padding: 40px;
    border-radius: 10px;
    border: #f8f9fa solid 1px;
    background-color: #f8f9fa;
    width: 100%;
}
.ant-picker-input {
    padding: 10px;
    border: none;
}

.react-datepicker-wrapper {
    width: 100%;
    height: 47px;
    padding: 20px;
}

.status {
    font-size: small;
    min-width: 170px;
    width: 180px;
    display: block;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
}

.pendingStatus {
    background-color: #e8df80;
    color: #d78c19ad;
}
.paymentIssue {
    background-color: #db9f9f;
    color: #991e35fc;
}

.orderamount {
    min-width: auto;
    text-align: center;
    width: 200px;
}

.tableBody {
    height: 220px;
}

.MuiBadge-anchorOriginTopRightRectangle{
    top: 12px !important;
    right: 14px !important;
}

.MuiBadge-badge{
    height: 15px !important;
    width: 15px !important;
    min-width: 16px !important;
}

.conversationsHistoryWrap {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    .conversationsHistory {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            border-top: 1px solid #ccc;
            padding-top: 24px;
            margin-top: 24px;
            &:first-child {
                border-top: 0px solid #ccc;
                padding-top: 0px;
                margin-top: 0px;
            }
            .requestBodyWrap {
                display: flex;
                justify-content: end;
                margin-bottom: 12px;
                .requestBody {
                    max-width: 85%;
                    padding: 8px;
                    background-color: #dbe1fe;
                    border-radius: 4px;
                    border: 1px solid #cad2fe;
                    .requestBodyWrapper {
                        display: flex;
                        justify-content: end;
                        line-break: anywhere;
                        span {
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            border-radius: 50%;
                            background-color: #6c5dd3;
                            display: block;
                            text-align: center;
                            line-height: 40px;
                            color: #fff;
                            margin-left: 12px;
                        }
                        p {
                            margin: 0;
                            color: #2e3f96;
                        }
                    }
                    .dateTime {
                        text-align: end;
                        color: #2e3f96;
                        margin: 12px 0 0 0;
                    }
                }
            }
            .response {
                max-width: 85%;
                padding: 8px;
                background-color: #fff5dc;
                border-radius: 4px;
                margin-bottom: 12px;
                border: 1px solid #fff1cb;
                .responseWrapper {
                    display: flex;
                    justify-content: start;
                    line-break: anywhere;
                    span {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        border-radius: 50%;
                        background-color: #665321;
                        display: block;
                        text-align: center;
                        line-height: 40px;
                        color: #fff;
                        margin-right: 12px;
                    }
                    p {
                        margin: 0;
                        color: #665321;
                    }
                }
                .dateTime {
                    color: #665321;
                    margin: 12px 0 0 0;
                    text-align: end;
                }
            }
        }
    }
}
