@media screen and (max-width:399px) {
    .pagination-num{
        flex-direction: column;
    }    
    .select-page{
        width: 30%;
        margin-top: 5%;
    }
    .card-footer-right{
        margin-left: 0;
    }
}