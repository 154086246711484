@media screen and (max-width: 600px) {
    .add-search {
        flex-direction: column !important;
        align-items: baseline !important;
    }
    .search-div {
        flex-direction: column !important;
        align-items: baseline !important;
        padding-top: 4px !important;
    }
    .search-btn {
        display: flex;
        flex-direction: row;
        gap: 5%;
    }
}

@media screen and (max-width: 374px) {
    .process-btn {
        width: 45% !important;
    }
    .cancel-btn {
        width: 42% !important;
    }
}

.interestsQuestionAnswerHeading {
    font-size: 19px;
    font-weight: 600;
}

.interestsQuestionAnswerQuestionContainer {
    padding: 10px 0;
}

.interestsQuestionContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 0 0;

    .interestsQuestionAnswerQuestion {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
}

.interestsAnswerContainer {
    .interestsAnswer {
        padding: 0 0 0 48px;
        font-size: 16px;
        margin: 0;
    }
}

.detailsLabel {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 5px 0;
}

.noFoundData {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: gray;
    margin: 0;
}
